import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import { loadStripe } from "@stripe/stripe-js"; // You'll need to install stripe using npm install @stripe/stripe-js
import { initGA, logPageView } from "../utils/gtag"; // Import the functions
import DataTable from "./DataTable";
import config from "../config/config";
import Loading from "./Loading";
import { convertTo24Hour } from "../utils/helper";
import GaugeChart from "react-gauge-chart";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Auth } from 'aws-amplify';
import { useAuth } from "./auth/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";

const stripePromise = loadStripe(config.stripeToken);

const getSydneyDate = () => moment().tz("Australia/Sydney");

function Home() {
    const { currentUser } = useAuth();
    // console.log('user info: ',currentUser);
    const [winPercentage, setWinPercentage] = useState(0);
    const [placePercentage, setPlacePercentage] = useState(0);
    const [roi, setRoi] = useState(0);
    const [placeRoi, setPlaceRoi] = useState(0);
    const [originalRaceData, setOriginalRaceData] = useState([]);
    const [displayedRaceData, setDisplayRaceData] = useState([]);
    const [venueList, setVenueList] = useState(["All"]);
    const [selectedVenue, setSelectedVenue] = useState("All");
    const [isValuedChecked, setIsValuedChecked] = useState(true);
    const [isRatedChecked, setIsRatedChecked] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [isAUNZChecked, setIsAUNZChecked] = useState(true);
    const [isIntChecked, setIsIntChecked] = useState(true);
    const navigate = useNavigate();

    const preprocessData = (data) =>
        data.map((item) => ({
            ...item,
            "Win $": item["Win $"] === 0 ? "-" : item["Win $"],
            "Place $": item["Place $"] === 0 ? "-" : item["Place $"],
        }));

    const getInitialDate = () => {
            const hash = window.location.hash.slice(1);
            if (hash) {
                const parsedDate = moment(hash, "YYYY-MM-DD", true);
                if (parsedDate.isValid()) {
                    return parsedDate.toDate();
                }
            }
            return getSydneyDate().toDate();
        };
    
    const [currentDate, setCurrentDate] = useState(getInitialDate());

    const isToday =
        currentDate.toDateString() === getSydneyDate().toDate().toDateString();

    useEffect(() => {
        initGA(); // Initialize Google Analytics
        logPageView(window.location.pathname); // Log the page view

        const fetchRatedData = async () => {
            try {
                setIsLoading(true);
                let response;
                if(currentUser && !isToday) {
                    const formattedDate = moment(currentDate)
                                            .tz("Australia/Sydney")
                                            .format("YYYYMMDD");
                    response = await fetch(`${config.openUrl}/R/${formattedDate}/rated`);

                } else {
                    response = await fetch(`${config.dataUrl}/R/rated`);
                }
                const data = await response.json();

                const ratedData = preprocessData(
                    data.map((item) => ({
                        ...item,
                        Type: ["Rated"],
                    }))
                );

                setOriginalRaceData((prevDataArray) => {
                    const updatedDataArray = [...prevDataArray];

                    ratedData.forEach((newData) => {
                        const existingIndex = updatedDataArray.findIndex(
                            (item) => item.Horse === newData.Horse
                        );
                        if (existingIndex !== -1) {
                            // Update existing item
                            if (
                                !updatedDataArray[existingIndex]["Type"].includes(
                                    newData.Type[0]
                                )
                            ) {
                                updatedDataArray[existingIndex]["Type"].push(
                                    newData.Type[0]
                                );
                            }
                        } else {
                            // Append new item
                            updatedDataArray.push(newData);
                        }
                    });

                    const uniqueVenues = Array.from(
                        new Set(updatedDataArray.map((item) => item.Venue))
                    );

                    setVenueList(uniqueVenues);
                    const sortedData = updatedDataArray.sort((a, b) => {
                        const timeA = convertTo24Hour(a.Time);
                        const timeB = convertTo24Hour(b.Time);
                        return timeA.localeCompare(timeB);
                    });

                    setDisplayRaceData(sortedData);
                    setIsLoading(false);

                    return sortedData;
                });
            } catch (error) {
                console.error("Error fetching rated data:", error);
            }
        };

        const fetchValueData = async () => {
            try {
                setIsLoading(true);
                let response;
                if(currentUser && !isToday) {
                    const formattedDate = moment(currentDate)
                                            .tz("Australia/Sydney")
                                            .format("YYYYMMDD");
                    response = await fetch(`${config.openUrl}/R/${formattedDate}/value`);

                } else {
                    response = await fetch(`${config.dataUrl}/R/value`);
                }
                const data = await response.json();

                const valueData = preprocessData(
                    data.map((item) => ({
                        ...item,
                        Type: ["Value"],
                    }))
                );

                setOriginalRaceData((prevDataArray) => {
                    const updatedDataArray = [...prevDataArray];

                    valueData.forEach((newData) => {
                        const existingIndex = updatedDataArray.findIndex(
                            (item) => item.Horse === newData.Horse
                        );
                        if (existingIndex !== -1) {
                            // Update existing item
                            if (
                                !updatedDataArray[existingIndex]["Type"].includes(
                                    newData.Type[0]
                                )
                            ) {
                                updatedDataArray[existingIndex]["Type"].push(
                                    newData.Type[0]
                                );
                            }
                        } else {
                            // Append new item
                            updatedDataArray.push(newData);
                        }
                    });

                    const uniqueVenues = Array.from(
                        new Set(updatedDataArray.map((item) => item.Venue))
                    );

                    setVenueList(uniqueVenues);
                    const sortedData = updatedDataArray.sort((a, b) => {
                        const timeA = convertTo24Hour(a.Time);
                        const timeB = convertTo24Hour(b.Time);
                        return timeA.localeCompare(timeB);
                    });
                    setDisplayRaceData(sortedData);
                    setIsLoading(false);
                    return sortedData;
                });
            } catch (error) {
                console.error("Error fetching Value data:", error);
            }
        };
        setOriginalRaceData([]); // Clear existing data
        setDisplayRaceData([]); // Clear displayed data
        fetchRatedData();
        fetchValueData();
    }, [currentUser,currentDate,isToday]);

    useEffect(() => {
            if (displayedRaceData.length > 0) {
                const validRaces = displayedRaceData.filter(race => !["Open", "Scr", "Abnd"].includes(race.Result));
                const totalBets = validRaces.length;
                const wins = validRaces.filter(race => race.Result === "1st").length;
                const places = validRaces.filter(race => ["1st", "2nd", "3rd"].includes(race.Result)).length;
                const totalWinAmount = validRaces.reduce((acc, race) => {
                    const winAmount = race["Tipped $"] && race["Win $"] !== "-"  && race["Win $"] !== 0
                        ? parseFloat(race["Tipped $"]) 
                        : (race["Win $"] !== "-" ? parseFloat(race["Win $"]) : 0);
                    return acc + winAmount;
                }, 0);
                
                const totalPlaceAmount = validRaces.reduce((acc, race) => {
                    const placeAmount = race["Tipped Place $"] && race["Place $"] !== "-" && race["Place $"] !== 0
                        ? parseFloat(race["Tipped Place $"]) 
                        : (race["Place $"] !== "-" ? parseFloat(race["Place $"]) : 0);
                    return acc + placeAmount;
                }, 0);
                const totalStaked = totalBets * 1; // Assuming $1 per bet
                
                setWinPercentage(totalBets > 0 ? wins / totalBets : 0);
                setPlacePercentage(totalBets > 0 ? places / totalBets : 0);
                setRoi(totalStaked > 0 ? (totalWinAmount - totalStaked) / totalStaked : 0);
                setPlaceRoi(totalStaked > 0 ? (totalPlaceAmount - totalStaked) / totalStaked : 0);
                // const roiValue = totalStaked > 0 ? (totalWinAmount - totalStaked) / totalStaked : -1;
                // setRoi(Math.max(0, Math.min((roiValue + 1) / 6, 1))); // Normalizing ROI from -100% to 500%
            }
        }, [displayedRaceData]);
    
        const barChartData = (roiValue) => ({
            labels: [""],
            datasets: [
                {
                    label: "ROI %",
                    maxBarThickness: 30,
                    data: [roiValue * 100],
                    backgroundColor: roiValue >= 0 ? "#4CAF50" : "#FF4E42",
                    borderRadius: 5,
                },
            ],
        });
    
        const barChartOptions = {
            indexAxis: "y", // Horizontal bar
            scales: {
                x: {
                    display: false,
                    min: -100, // Fixed min value
                    max: 500,  // Fixed max value
                    grid: {
                        color: (context) => context.tick.value === 0 ? "#000" : "rgba(0,0,0,0.1)", // Line at zero
                    },
                },
                y: { 
                    display: false, // ✅ Hides Y-axis labels
                    grid: { drawTicks: false } // ✅ Removes unnecessary spacing
                }
            },
            plugins: {
                legend: { display: false },
                tooltip: { 
                    enabled: true,  // ✅ Enables tooltip
                    callbacks: {
                        label: (context) => {
                            let value = context.raw;
                            return `ROI: ${value.toFixed(2)}%`; // ✅ Formats tooltip text
                        }
                    },
                    backgroundColor: "rgba(0, 0, 0, 0.8)", // ✅ Dark background for better visibility
                    titleColor: "#fff",
                    bodyColor: "#fff",
                    padding: 8,
                    borderWidth: 1,
                    borderColor: "#fff",
                },
                datalabels: {
                    anchor: "center",
                    align: "center",
                    color: "white",
                    font: { weight: "bold", size: 14 },
                    formatter: (value) => `${value.toFixed(0)}%`,
                },
            },
            responsive: true,
            maintainAspectRatio: false,
            //barThickness: 25,  // ✅ Prevents automatic resizing
            categoryPercentage: 1.0, // ✅ Ensures the bar uses full height
            barPercentage: 1.0, // ✅ Removes extra padding
            backgroundColor: "rgba(240, 240, 240, 1)" // ✅ Sets a light gray background
        };

    const columns = React.useMemo(
        () => [
            {
                Header: "Time",
                accessor: "Time",
                Class: "card-time",
            },
            {
                Header: "Venue",
                accessor: "Venue",
                Class: "card-venue",
            },
            {
                Header: "Region",
                accessor: "Region",
            },
            {
                Header: "Race",
                accessor: "Race",
                Class: "card-race",
            },
            {
                Header: "Tip",
                accessor: "Tip",
                Class: "card-tip",
            },
            {
                Header: "Horse",
                accessor: "Horse",
                Class: "card-horse",
            },
            {
                Header: "Result",
                accessor: "Result",
            },
            {
                Header: "Win $",
                accessor: "Win $",
                Class: "card-win",
            },
            {
                Header: "Place $",
                accessor: "Place $",
                Class: "card-place",
            },
            {
                Header: "Tipped $",
                accessor: "Tipped $",
                Cell: ({ row }) => row.original["Tipped $"] ?? "-", 
            },
            {
                Header: "Place",
                accessor: "Tipped Place $",
                Cell: ({ row }) => row.original["Tipped Place $"] ?? "-", 
            },
            {
                Header: "Runners",
                accessor: "Runners",
            },
            {
                Header: "Distance",
                accessor: "Distance",
            },

            {
                Header: "Type",
                accessor: "Type",
                Class: "card-type",
            },
        ],
        []
    );

    const handleButtonClick = async (event) => {
        const stripe = await stripePromise;
        const mode = event.target.dataset.checkoutMode;
        const priceId = event.target.dataset.priceId;
        const items = [{ price: priceId, quantity: 1 }];

        const DOMAIN = window.location.href.replace(/[^/]*$/, "");

        stripe
            .redirectToCheckout({
                mode,
                lineItems: items,
                successUrl: `${DOMAIN}success?session_id={CHECKOUT_SESSION_ID}`,
                cancelUrl: `${DOMAIN}index.html?session_id={CHECKOUT_SESSION_ID}`,
            })
            .then((result) => {
                if (result.error) {
                    const displayError =
                        document.getElementById("error-message");
                    displayError.textContent = result.error.message;
                }
            });
    };

    const updateRaceData = (venue, valueData, ratedData, aunz, international) => {
        const tempRaceData = originalRaceData.filter((item) => {
            let tmpReturn = true
            if (item.Venue !== venue && venue !== "All") {
                return false;
            }

            if (valueData && item.Type.includes("Value")) {
                tmpReturn = true;
            } else if (ratedData && item.Type.includes("Rated")) {
                tmpReturn = true;
            } else { 
                tmpReturn =  false 
            }

            // Handle Region Selection
            if (tmpReturn && aunz && item.Region == "AU/NZ") {
                tmpReturn =  true;
            } else if (tmpReturn && international && item.Region == "Int") {
                tmpReturn =  true;
            }  else { 
                tmpReturn =  false 
            }

            return tmpReturn;
        });

        setDisplayRaceData(tempRaceData);
    };

    const handleVenueChange = (event) => {
        setSelectedVenue(event.target.value);
        updateRaceData(event.target.value, isValuedChecked, isRatedChecked,isAUNZChecked,isIntChecked);
    };

    const handleValueDataChange = () => {
        setIsValuedChecked((prevState) => {
            updateRaceData(selectedVenue, !prevState, isRatedChecked,isAUNZChecked,isIntChecked);

            return !prevState;
        });
    };

    const handleRatedDataChange = () => {
        setIsRatedChecked((prevState) => {
            updateRaceData(selectedVenue, isValuedChecked, !prevState,isAUNZChecked,isIntChecked);
            return !prevState;
        });
    };

    const handleAUNZChange = () => {
        setIsAUNZChecked((prevState) => {
            updateRaceData(selectedVenue, isValuedChecked, isRatedChecked,!prevState,isIntChecked);
            return !prevState;
        });
    };

    const handleIntChange = () => {
        setIsIntChecked((prevState) => {
            updateRaceData(selectedVenue, isValuedChecked, isRatedChecked,isAUNZChecked,!prevState);
            return !prevState;
        });
    };

    const handlePreviousDate = () => {
        const newDate = moment(currentDate).subtract(1, "days").toDate();
        setCurrentDate(newDate);
        setSelectedVenue("All"); // Reset venue selection
        setIsRatedChecked(true); // Reset filter
        setIsValuedChecked(true); // Reset filter
        setIsAUNZChecked(true); // Reset filter
        setIsIntChecked(true); // Reset filter
        navigate(
            `#${moment(newDate).tz("Australia/Sydney").format("YYYY-MM-DD")}`
        );
    };

    const handleNextDate = () => {
        const newDate = moment(currentDate).add(1, "days").toDate();
        if (
            moment(newDate)
                .tz("Australia/Sydney")
                .isSameOrBefore(getSydneyDate())
        ) {
            setCurrentDate(newDate);
            setSelectedVenue("All"); // Reset venue selection
            setIsRatedChecked(true); // Reset filter
            setIsValuedChecked(true); // Reset filter
            setIsAUNZChecked(true); // Reset filter
            setIsIntChecked(true); // Reset filter
            navigate(
                `#${moment(newDate)
                    .tz("Australia/Sydney")
                    .format("YYYY-MM-DD")}`
            );
        }
    };

    return (
        <div className="container">
            {isLoading ? (
                <Loading />
            ) : (
                <section
                    className="ctext section-body"
                    id="header1-0"
                    data-rv-view="0"
                >
                    <div className="filter-container">
                        <h1 className="table-header">
                                                    {isToday || (isToday && !currentUser) 
                                                        ? "Today's Free Tips  "
                                                        : `Results for ${moment(currentDate)
                                                              .tz("Australia/Sydney")
                                                              .format("DD/MM/YYYY")}  `}
                                                    {currentUser && (
                                                        <>
                                                    <button
                                                        id="prev-date-button"
                                                        onClick={handlePreviousDate}
                                                        className="nav-arrow"
                                                    >
                                                        &#8592;
                                                    </button>
                                                    <button
                                                        id="next-date-button"
                                                        onClick={handleNextDate}
                                                        className="nav-arrow"
                                                        disabled={isToday}
                                                    >
                                                        &#8594;
                                                    </button> 
                                                    </>
                                                     )}
                                                </h1>
                        <div className="filter-section">
                            <select
                                className="filter-dropdown"
                                onChange={handleVenueChange}
                            >
                                <option value="All">All</option>
                                {venueList.map((venue) => {
                                    return (
                                        <option value={venue} key={venue}>
                                            {venue}
                                        </option>
                                    );
                                })}
                            </select>
                            <div className="filter-group">
                                <div className="filter-checkbox">
                                    <label className="switch-label aunz">
                                        AU/NZ
                                    </label>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            checked={isAUNZChecked}
                                            onChange={handleAUNZChange}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className="filter-checkbox">
                                    <label className="switch-label int">
                                        Int.
                                    </label>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            checked={isIntChecked}
                                            onChange={handleIntChange}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className="filter-checkbox">
                                    <label className="switch-label valued">
                                        Value
                                    </label>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            checked={isValuedChecked}
                                            onChange={handleValueDataChange}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className="filter-checkbox">
                                    <label className="switch-label rated">
                                        Rated
                                    </label>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            checked={isRatedChecked}
                                            onChange={handleRatedDataChange}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="filter-container gauge-container">
                        <div className="gauge-chart">
                            <h3>Win %</h3>
                            <GaugeChart 
                                id="win-gauge"
                                nrOfLevels={3} 
                                colors={["#FF4E42", "#FFC107", "#4CAF50"]} 
                                arcsLength={[0.3, 0.2, 0.5]}
                                arcWidth={0.3}
                                percent={winPercentage} 
                                textColor={"#000"}
                            /> 
                            <div className="chart-container" style={{ height: "30px", width: "100%", overflow: "hidden" }}> 
                                <Bar data={barChartData(roi)} options={barChartOptions} plugins={[ChartDataLabels]}/>
                            </div>
                        </div>
                        
                        <div className="gauge-chart">
                            <h3>Place %</h3>
                            <GaugeChart 
                                id="place-gauge"
                                nrOfLevels={3} 
                                colors={["#FF4E42", "#FFC107", "#4CAF50"]} 
                                arcWidth={0.3}
                                percent={placePercentage} 
                                textColor={"#000"}
                            />
                            <div className="chart-container"  style={{ height: "30px", width: "100%", overflow: "hidden" }}> 
                            <Bar data={barChartData(placeRoi)} options={barChartOptions} plugins={[ChartDataLabels]}/>
                            </div>
                        </div>
                    </div>
                    <div className="mbr-table-cell align-content-center ctext">
                        <div className="container">
                            <div className="">
                                <DataTable
                                    data={displayedRaceData}
                                    columns={columns}
                                />
                            </div>
                        </div>
                    </div>

                    <div id="error-message"></div>
                    <div className="modal"></div>
                </section>
            )}
        </div>
    );
}

export default Home;
