import React, { useEffect, useState } from "react";
import moment from 'moment-timezone';
import { loadStripe } from "@stripe/stripe-js";
import { useTable, useExpanded } from "react-table";
import { initGA, logPageView } from "../utils/gtag";
import { useAuth, getValidToken } from "./auth/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import config from "../config/config";
import Loading from "./Loading";
import useDocumentTitle from "../hooks/useDocumentTitle";
import { Helmet } from "react-helmet";
import NestedDataTable from "./NestedDataTable";

const stripePromise = loadStripe(config.stripeToken);

const getSydneyDate = () => moment().tz("Australia/Sydney");

function First4() {
    useDocumentTitle("RippaDay - Members - First 4");
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const { currentUser } = useAuth();
    const [token, setToken] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedVenue, setSelectedVenue] = useState("All");
    const [currentDate, setCurrentDate] = useState(getSydneyDate().toDate());
    const navigate = useNavigate();
    const location = useLocation();

    const isToday = currentDate.toDateString() === getSydneyDate().toDate().toDateString();

    useEffect(() => {
        initGA();
        logPageView(window.location.pathname);
        const fetchToken = async () => {
            try {
                const fetchedToken = await getValidToken();
                setToken(fetchedToken);
            } catch (error) {
                console.error("Error fetching token:", error);
                // Redirect to the login screen
                window.location.href = "/signin";
            }
        };

        fetchToken();

        const hashDate = location.hash.replace("#", "");
        if (hashDate) {
            const parsedDate = moment.tz(hashDate, "Australia/Sydney").toDate();
            if (!isNaN(parsedDate)) {
                setCurrentDate(parsedDate);
            }
        }
    }, [location.hash]);

    useEffect(() => {
        if (token) {
            const fetchRaceData = async (date) => {
                try {
                    setIsLoading(true);
                    const formattedDate = moment(date).tz("Australia/Sydney").format("YYYYMMDD");
                    console.log("Getting data for:", formattedDate);
                    const response = await fetch(
                        `${config.membersUrl}/R/${formattedDate}/first4`,
                        {
                            headers: {
                                authorization: `${token}`,
                            },
                        }
                    );
                    const rawData = await response.json();
                    setIsLoading(false);

                    console.log("Fetched data:", rawData);

                    if (rawData && Array.isArray(rawData)) {
                        const transformedData = transformData(rawData);
                        setData(transformedData);
                        setFilteredData(transformedData);
                    } else {
                        console.log("No data found");
                    }
                } catch (error) {
                    console.error("Error fetching race data:", error);
                    navigate("/signin");
                }
            };

            fetchRaceData(currentDate);
        }
    }, [token, currentDate]);

    useEffect(() => {
        if (selectedVenue === "All") {
            setFilteredData(data);
        } else {
            setFilteredData(data.filter((item) => item.Venue === selectedVenue));
        }
    }, [selectedVenue, data]);

    const transformData = (rawData) => {
        const groupedData = rawData.reduce((acc, item) => {
            const key = `${item.Date}-${item.Venue}-${item.Race}`;
            if (!acc[key]) {
                acc[key] = {
                    Date: item.Date,
                    Venue: item.Venue,
                    Race: item.Race,
                    Time: item.Time,
                    NumberOfRunners: item.NumberOfRunners,
                    Distance: item.Distance,
                    Maiden: item.Maiden,
                    tips: [],
                };
            }
            acc[key].tips.push({
                TipNo: item.TipNo,
                Tip: item.Tip,
                Name: item.Name,
                Result: item.Result,
                Rating: item.Rating,
                Win: item.Win,
                Place: item.Place,
                Jockey: item.Jockey,
                Trainer: item.Trainer.replace(/&Amp;/g, "&"),
                Barrier: item.Barrier,
                Tip_Win: item.Tip_Win,
                Tip_Place: item.Tip_Place,
            });

            return acc;
        }, {});

        return Object.values(groupedData).map((item) => {
            const results = item.tips.map((tip) => tip.Result);
            const hasFirst = results.includes("1st");
            const hasSecond = results.includes("2nd");
            const hasThird = results.includes("3rd");
            const hasFourth = results.includes("4th");
            const hasOpen = results.includes("Open");
            const hasLose = results.includes("Lose");
            const abandoned = results.includes("Abnd");

            let result = "";
            if (hasFirst) result = "1st";
            else if (hasSecond) result = "2nd";
            else if (hasThird) result = "3rd";
            else if (hasFourth) result = "4th";
            else if (hasOpen) result = "Open";
            else if (hasLose) result = "Lose";
            else if (abandoned) result = "Abandoned";

            if (hasFirst && hasSecond && hasThird && hasFourth) {
                result += ", First 4";
            } else if (hasFirst && hasSecond && hasThird) {
                result += ", Trifecta";
            } else if (hasFirst && hasSecond) {
                result += ", Exacta";
            }

            return { ...item, Result: result };
        });
    };

    const columns = React.useMemo(
        () => [
            {
                Header: "Time",
                accessor: "Time",
                Class: "card-time",
            },
            {
                Header: "Venue",
                accessor: "Venue",
                Class: "card-venue",
            },
            {
                Header: "Race",
                accessor: "Race",
                Class: "card-race",
            },
            {
                Header: "Tips",
                accessor: "tips",
                Cell: ({ value }) => value.map((tip) => tip.Rating >= 80 ? `*${tip.Tip}` : tip.Tip).join("-"),
                Class: "card-tip",
            },
            {
                Header: "Result",
                accessor: "Result",
            },
            {
                Header: "Runners",
                accessor: "NumberOfRunners",
            },
            {
                Header: "Distance",
                accessor: "Distance",
            },
            {
                Header: "Maiden",
                accessor: "Maiden",
            },
        ],
        []
    );

    const nestedColumns = React.useMemo(
        () => [
            {
                Header: "Tip No",
                accessor: "TipNo",
                Class: "hidden",
            },
            {
                Header: "Number",
                accessor: "Tip",
            },
            {
                Header: "Name",
                accessor: "Name",
            },
            {
                Header: "Result",
                accessor: "Result",
            },
            {
                Header: "Rating",
                accessor: "Rating",
            },
            {
                Header: "Win $",
                accessor: "Win",
            },
            {
                Header: "Place $",
                accessor: "Place",
            },
            {
                Header: "Tipped $",
                accessor: "Tip_Win",
            },
            {
                Header: "Place",
                accessor: "Tip_Place",
            },
            {
                Header: "Barrier",
                accessor: "Barrier",
                Class: "hidden",
            },
            {
                Header: "Jockey",
                accessor: "Jockey",
                Class: "hidden",
            },
            {
                Header: "Trainer",
                accessor: "Trainer",
                Class: "hidden",
            },
        ],
        []
    );

    const venues = [...new Set(data.map((item) => item.Venue))];

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable({ columns, data: filteredData }, useExpanded);

    const handlePreviousDate = () => {
        const newDate = moment(currentDate).subtract(1, 'days').toDate();
        setCurrentDate(newDate);
        setSelectedVenue("All"); // Reset venue selection
        navigate(`#${moment(newDate).tz("Australia/Sydney").format("YYYY-MM-DD")}`);
    };

    const handleNextDate = () => {
        const newDate = moment(currentDate).add(1, 'days').toDate();
        if (moment(newDate).tz("Australia/Sydney").isSameOrBefore(getSydneyDate())) {
            setCurrentDate(newDate);
            setSelectedVenue("All"); // Reset venue selection
            navigate(`#${moment(newDate).tz("Australia/Sydney").format("YYYY-MM-DD")}`);
        }
    };

    useEffect(() => {
        console.log("Current Date Updated:", currentDate);
    }, [currentDate]);

    return (
        <div className="container">
            <Helmet>
                <title>RippaDay - Members - First 4</title>
                <meta
                    name="description"
                    content="Exclusive members tips. Top 4 Tips per race"
                />
                <meta
                    name="keywords"
                    content="Exclusive members tips. Best Bets. Sure Thing for venues including Eagle Farm, Caulfield, Flemington, Moonee Valley, Rosehill and Randwick"
                />
            </Helmet>
            {isLoading ? (
                <Loading />
            ) : (
                <section
                    className="ctext section-body"
                    id="header1-0"
                    data-rv-view="0"
                >
                    <div className="filter-container">
                        <h1 className="table-header">
                            {isToday ? "Today's Members Top 4 Tips  " : `Results for ${moment(currentDate).tz("Australia/Sydney").format("DD/MM/YYYY")}  `}
                            <button id="prev-date-button" onClick={handlePreviousDate} className="nav-arrow">
                                &#8592;
                            </button>
                            <button
                                id="next-date-button"
                                onClick={handleNextDate}
                                className="nav-arrow"
                                disabled={isToday}
                            >
                                &#8594;
                            </button>
                        </h1>
                        <div className="filter-section">
                            <select
                                className="filter-dropdown"
                                id="venue-filter"
                                value={selectedVenue}
                                onChange={(e) =>
                                    setSelectedVenue(e.target.value)
                                }
                            >
                                <option value="All">All</option>
                                {venues.map((venue, index) => (
                                    <option key={index} value={venue}>
                                        {venue}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="container">
                        <NestedDataTable
                            data={filteredData}
                            columns={columns}
                            nestedColumns={nestedColumns}
                        />
                    </div>
                </section>
            )}
        </div>
    );
}

export default First4;
